import logo from './logo.svg';
import './App.css';
import Lenis from '@studio-freight/lenis';
import SplitType from 'split-type';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import imgH1 from './img/h1-text-purple.png'
import imgH2 from './img/h1-text-white.png'
import pcFront from './img/pc-front.png'
import pcBack from './img/pc-back.png'
import { useRef, useEffect } from 'react';
import React from 'react';
import { Spotify } from 'react-spotify-embed';
gsap.registerPlugin(ScrollTrigger);

function App() {
  const lenis = new Lenis()

  lenis.on('scroll', (e) => {
    console.log(e)
  })
  
  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }
  
  requestAnimationFrame(raf)

  useEffect(() => {
    document.title ='Happy Aja Day!'

    const splitTypes = document.querySelectorAll('.reveal-type')
    splitTypes.forEach((char, i) => {
      const text = new SplitType(char, {types: 'lines'})

      gsap.from(text.lines, {
        scrollTrigger: {
          trigger: char,
          start: 'top 90%',
          end: 'top 20%',
          scrub: true,
          markers: false
        },
        y: 10,
        x: -50,
        opacity: 0,
        stagger: 0.1
      })
    })

    const revealPc = document.querySelectorAll('.reveal-pc')
    revealPc.forEach((pc, i) => {
      gsap.fromTo(pc, {
        scrollTrigger: {
          trigger: pc,
          start: 'top 80%',
          end: 'top 40%',
          scrub: true,
          markers: false
        },
        y: 10,
        x: -50,
        opacity: 0,
        stagger: 0.1
      },
      {
        scrollTrigger: {
          trigger: pc,
          start: 'top 80%',
          end: 'top 40%',
          scrub: true,
          markers: false
        },
        y: 0,
        x: 0,
        opacity: 1,
        stagger: 0.1
      })
    })

    const revealLoop = document.querySelectorAll('.reveal-loop')
    revealLoop.forEach((lp, i) => {
      gsap.fromTo(lp, {
        // scrollTrigger: {
        //   trigger: lp,
        //   start: 'top 80%',
        //   end: 'top 20%',
        //   scrub: true,
        //   markers: false
        // },
        y: 0,
        x: 0,
        opacity: 0,
        stagger: 0.1,
        repeat: -1
      },
      {
        // scrollTrigger: {
        //   trigger: lp,
        //   start: 'top 80%',
        //   end: 'top 20%',
        //   scrub: true,
        //   markers: false
        // },
        y: -10,
        x: 0,
        opacity: 1,
        ease: "back.out(1.7)",
        stagger: 0.1,
        yoyo: true,
        repeat: -1,
        duration: 1
      })
    })

    const revealHeader = document.querySelectorAll('.reveal-header')
    revealHeader.forEach((header, i) => {
      gsap.fromTo(header, {
        scrollTrigger: {
          trigger: header,
          start: 'top 50%',
          end: 'top 20%',
          scrub: true,
          markers: false
        },
        y: 100,
        x: 0,
        opacity: 0.5,
        stagger: 0.1
      },
      {
        scrollTrigger: {
          trigger: header,
          start: 'top 50%',
          end: 'top 20%',
          scrub: true,
          markers: false
        },
        y: 0,
        x: 0,
        opacity: 1,
        stagger: 0.1,
        duration: 1
      })
    })

  }, [])

  function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p>{str}</p>);
  }

  return (
      <div className="App bg-image">
        <section>
            <p className='reveal-loop half-height'>Scroll ka lang pi ⏬</p>
        </section>
          <section>
            <h1 className='reveal-header'>
                <img className='header-img' src={imgH1}/>
            </h1>
          </section>
          <section>
            <h2 className='reveal-type'>hi hello mic test</h2>
          </section>
          <section>
            <p className='reveal-type'>
              <NewlineText text={"Ajani, \n\nHappy birthday! ❤️\nSo this is a little change in plans. As we talked about the other day, I supposedly had a gift for you but that's okay. Instead of that, I've just compiled some of the things in that gift na pwede kong ilagay here.\n\n🫰🏻"} />
            </p>
          </section>
          <section>
            <p className='reveal-type'>
              <NewlineText text={"With that out of the way, there are also some thoughts I'd like to say so please bear with me — I'm not really good with words but here goes.\nUna sa lahat, I wish you have a great birthday! By this time, kakatapos pa lang ng midyear sem mo. Tapos magsstart na agad yung new sem :< I hope you have a restful break (kahit na laging saglitan lang pahinga mo 🥺🥺🥺) and especially, sana you have a wonderful time on your birthday. \n I wish that you'll be able to spend your time on this day on things that you'd love to do and  have fun doing them. At sa kung anumang mga bagay na hinihiling mo, I hope you'll get them or at least be a few steps closer to them."} />
            </p>
          </section>
          <section>
            <p className='reveal-type'>
              <NewlineText text={"With you being a busy engineering major – lalo na in the few months of us knowing each other, I really sensed how much you value your acads (and you're doing amazing btw!). I want you to know na if things get tough, if life seems to be unfair – you can talk, rant or just simply ramble anything to me.\nOf course, it's completely up to you naman if you'd want to do those things, but this is just me saying na if you ever need it, I'm willing to lend an ear to you – whether gusto mo lang na makinig ako or I say a few words of my own. Things will get tough, and normal lang naman mahirapan. Gusto ko lang ipaalam sa'yo na I am around if you ever need anything. \nDon't hesitate to reach out po!\n And don't forget to take breaks, ha! You deserve to take a rest from time to time with all the effort you put in, remember to take care of yourself. Being unproductive sometimes is not necessarily a bad thing. Your college life still has a few years ahead of it and there's no denying that those years can and probably will be busy and draining."} />
            </p>
          </section>
          <section>
            <p className='reveal-type'>
              <NewlineText text={"And things won’t always go the way we want it to. Inevitably, things will go sideways and sometimes those things are not in our control and they’re not necessarily our fault. But even if they are, it’s not something we can't move forward from. \nWala namang challenge na hindi mo kayang i-overcome – hindi ‘yung mga patong-patong na requirements, hindi ‘yung mga prof na forda roleta ang grades, hindi kahit anumang pagsubok. I’ve said this before but always remember that I’m rooting for you.\nProud of you, future civil engineer! Always 🤍"} />
            </p>
          </section>
          <section>
            <p className='reveal-type'>
              <NewlineText text={"Lastly, there’s a lot of things I’m thankful of ENHYPEN for but never did it cross my mind na isa sa mga bagay na magiging thankful ako para sa kanila is them being the reason how I met you. I’m really grateful that I knew you, Aja. That Manifesto day is special for all the moments I treasure as an ENGENE but it was made more special because of that interaction in line of us lower box attendees. I’m happy to have you as someone I can share one of my interests with.\nYou may think na wala ka namang masyadong ginawa sa buhay ko for me to appreciate you like this but gaya nga ng sabi ko, there's no need for big efforts to be appreciated – even the most mundane things can have impact. And for me, I just feel good whenever I talk to you. Sana lang di ka mainis kasi may pagkapapansin talaga ako minsan char hsksdakkasd \nYou mean a lot to me, Ajani. I know we’ve talked about this briefly a while back but this is just me letting you know that I continue to feel the same way about you. Don't worry, though, tanda ko pa rin naman yung mga sinabi mo when we talked about it back then.\n I'd love to pursue you but given that it's not a priority for you right now as you said, I respect that. I just really want to make my intentions clear and say that I still like you but I know my boundaries :) \n You don't have to respond to this naman, me speaking my mind lang po itu !! 😆 "} />
            </p>
          </section>
          <section>
            <p className='reveal-type'>
              <NewlineText text={"Sorry, ang dami ko na yatang sinabi hahaha. \n I hope I didn’t bore you with words. Also sana I didn’t spook you with all the things I said! Hahaha I know I don’t always say things like this to you (maybe except kapag nagrereply ako sa mga selfie story mo sa IG hahaha), so I hope you don’t mind all the fluff. \n Again, happy birthday u cutie! You deserve all good things in life, enjoy your day! \nSee you soon, yes? (what eaf pagbalik ko from Japan hatid ko na si Heeseung as pasalubong ems pero realg8) \n—Vince 🫶🏻"} />
            </p>
          </section>
          <sbreak>
            <h1 className='reveal-type'>
                <img className='header-img' src={imgH1}/>
            </h1>
          </sbreak>
          <section>
            <p2 className='reveal-type'>
              <NewlineText text={"This is just a collection of songs that make me think of you. Fuel sa delusyon, ems! 🫣😂\n You're probably familiar with some of these but I hope you'll hear something new that tickles your fancy. 🫶🏻"} />
              <Spotify className='spotify' link="https://open.spotify.com/playlist/2gt5PZ2U9ZQkYZCWR3X9Vl?si=73c76cd813a34992" />
            </p2>
          </section>
          <sbreak>
            <h1 className='reveal-type'>
                <img className='header-img' src={imgH1}/>
            </h1>
          </sbreak>
          <section>
            <pc>
              <pcb className='reveal-pc'></pcb>
              <pcf className='reveal-pc'></pcf>
              <br></br>
              <p3><p className='reveal-type'><NewlineText text={" \nActually, part of my gift aside from the letter above and another small thing 💐, pauuwiin ko lang naman 'to sa'yo ahshasdhdh\n'Di ko mapauwi si Heeseung (real) eh, sa Fate tour na lang. We'll get those tickets. 🤞🏻 \nI'll keep this for you and if you decide na you want it, let me know lang po :)\n\nThat is all, I hope this year will be good to you. 🥺\n Happy birthday, ulit! 🤍🤍🤍"}/></p></p3>
            </pc>
          </section>
          <sbreak>
          <h1 className='end'>
              <img className='header-img' src={imgH1}/>
          </h1>
          </sbreak>
      </div>
  );
}

export default App;
